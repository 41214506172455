
import {el} from '@elemaudio/core';


import Fidget, { PointEvent } from '../../Engines/VisualEngine/Fidget/Fidget';
import AyisenMath from '../../libs/Math';
import { R2New } from '../../libs/Math/R2';
import VisualEngine from '../../Engines/VisualEngine';
import { SampleInfo } from '../../Engines/AudioEngine/ela/sample/types';


export interface FlingerBall {

    engaged: boolean,
    anchor: {
        location: R2New.Vector
        width: number,
    },
    body: {
        location: R2New.Vector
        width: number, 
        centerWidth: number,
        outterColor: string,
        innerColor: string,
    },
    // Velocity in pixels / second
    velocity: R2New.Vector,

    samplePath?: SampleInfo,

}

export default class SonicFlinger extends Fidget {

    centerFlinger: FlingerBall = this.getBaselineFlinger();
    runningBalls: FlingerBall[] = [];

    setBaselineFlinger() {
        this.centerFlinger = this.getBaselineFlinger();
    }

    getBaselineFlinger () {

        const output: FlingerBall = {
            engaged: false,
            anchor: {
                location: R2New.newVector(
                    this.canvas.width / 2,
                    this.canvas.height / 2,
                ),
                width: 20,
            },
            body: {
                location: R2New.newVector(
                    this.canvas.width / 2,
                    this.canvas.height / 2,
                ),
                width: 26, 
                centerWidth: 10,
                outterColor: AyisenMath.Random.randomColorNoAlpha(),
                innerColor: AyisenMath.Random.randomColorNoAlpha(),
            },
            // Velocity in pixels / second
            velocity: R2New.newVector(
                0,
                0,
            ),
        };

        return output;

    }


    uncoupleCurrentFlinger() {

        // Asign a sound 
        // note: moved audio core to an atom
        // this.centerFlinger.samplePath = this.audioCore.sampleHandler?.getRandomAyisenKitSample() ?? undefined;

        // Release the current one!
        this.runningBalls.push(this.centerFlinger);
        this.setBaselineFlinger();

    }

    onCanvasDimsLoad = () => {

        // Canvas dims loaded 
        // Center the flinger!
        this.setBaselineFlinger();

    }



    async releaseFlinger (coord: PointEvent) {

        // Sim dynamics
        
        // Displacement from anchor
        const anchorDisp = await AyisenMath.R2New.distance(this.centerFlinger.body.location, this.centerFlinger.anchor.location);

        // Spring force
        // TODO @Marcel: Spring force must act over the WHOLE release period.
        const springK = 15.0;
        const flingerM = 1;
        const springE = 0.5 * springK * Math.pow(anchorDisp, 2);

        // For now, convert sping energy into velocity, raw!
        let kineticE = springE;
        let v = Math.sqrt( (2 * kineticE) / flingerM );

        // Get the unit vector to go along!
        let unitV = {
            x: (this.centerFlinger.anchor.location.x - this.centerFlinger.body.location.x) / anchorDisp,
            y: (this.centerFlinger.anchor.location.y - this.centerFlinger.body.location.y) / anchorDisp,
        };

        // Update V
        this.centerFlinger.velocity = R2New.newVector(
            unitV.x * v,
            unitV.y * v
        );

        // Release
        this.centerFlinger.engaged = false;

        // Uncouple!
        this.uncoupleCurrentFlinger();

    }



    mouseUp = (e: PointEvent) => {

        // TODO @Marcel: should handle on exit as well lol. 
        
        if (this.centerFlinger.engaged)
            this.releaseFlinger(e);


    }

    onMouseDown = (e: PointEvent) => {

        const isOver = VisualEngine.canvas.isOverCircle(this.centerFlinger.body.location, this.centerFlinger.body.width, R2New.newVector(e.x, e.y));

        // Over Flinger? 
        if (isOver) {
            
            // Engage centerFlinger
            this.centerFlinger.engaged = true;

        }

    }


    onDrag = (e: PointEvent) => {

        if (this.centerFlinger.engaged) {

            // Update centerFlinger loc!
            this.centerFlinger.body.location.x = e.x;
            this.centerFlinger.body.location.y = e.y;

        }
        
    }


    updateFlingerLocs () {

        // Main flinger
        this.centerFlinger = this.updateFlingerLoc(this.centerFlinger);

        // Other
        this.runningBalls = this.runningBalls.map(flinger => {
            return this.updateFlingerLoc(flinger);
        });

    }


    updateFlingerLoc (flinger: FlingerBall) {

        // Update based on velocity, and time elapsed.
        /*

        Can probably use some sort of like stocastic update... that's prob find for this sim. 

        Don't want to base it off of the global time... b/c forces can act on it in between. 

        */

        if (!this.lastRenderT)
            return flinger;
        
        // Get increment in seconds
        const timeStepS = (Date.now() - this.lastRenderT) / 1000;

        // Update the location (pixels per second)
        flinger.body.location.x += flinger.velocity.x * timeStepS;
        flinger.body.location.y += flinger.velocity.y * timeStepS;

        let collision = false;


        // L-Wall collision? 
        if ((flinger.body.location.x - flinger.body.width/2) < 0 && flinger.velocity.x < 0) {
            flinger.velocity.x *= -1;
            collision = true;
        }

        // R-Wall collision? 
        else if ((flinger.body.location.x + flinger.body.width/2) > this.canvas.width && flinger.velocity.x > 0) {
            flinger.velocity.x *= -1;
            collision = true;
        }

        // T-Wall collision? 
        else if ((flinger.body.location.y - flinger.body.width/2) < 0 && flinger.velocity.y < 0) {
            flinger.velocity.y *= -1;
            collision = true;
        }

        // B-Wall collision? 
        else if ((flinger.body.location.y + flinger.body.width/2) > this.canvas.height && flinger.velocity.y > 0) {
            flinger.velocity.y *= -1;
            collision = true;
        }

        if (collision && flinger.samplePath) {

            // note: moved audio core out to an atom, grab from there if u need

        }

        return flinger;


    }


    async renderFlingerBody(flinger: FlingerBall) {

        if (!this.ctx)
            return;

        VisualEngine.canvas.drawCircle(this.ctx, flinger.body.location.x, flinger.body.location.y, 26, flinger.body.outterColor);
        VisualEngine.canvas.drawCircle(this.ctx, flinger.body.location.x, flinger.body.location.y, 10, flinger.body.innerColor);

    }


    // Animate frame
    renderFrame = async () => {

        if (!this.ctx)
            return;

        // Render / Update ripples

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);


        // Anchor Flinger Connector
        const anchorSep = await AyisenMath.R2New.distance(this.centerFlinger.body.location, this.centerFlinger.anchor.location);
        if ( anchorSep > 0.001 && this.centerFlinger.engaged) {
            VisualEngine.canvas.drawLine(this.ctx, this.centerFlinger.anchor.location, this.centerFlinger.body.location, 1);
        }

        // Draw Anchor
        VisualEngine.canvas.drawCrosshair(this.ctx, this.centerFlinger.anchor.location, this.centerFlinger.anchor.width);

        // Draw Current Flinger 
        // 'rgb(32, 170, 230)'
        // 'rgb(255, 60, 60)'
        this.renderFlingerBody(this.centerFlinger);

        // Draw running flingers
        for (let i=0; i<this.runningBalls.length; i++)
            this.renderFlingerBody(this.runningBalls[i]);

        // Update Location
        this.updateFlingerLocs();


    }






}