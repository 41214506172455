


import React, {useState, useRef, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Fidget, { SharedStateEntity } from '../../Engines/VisualEngine/Fidget/Fidget';


import '../../styles/SimCanvas.css';


export interface SimCanvasProps {

    attachNewSimulator: (canvas: HTMLCanvasElement) => Fidget,
    key: number,

    // TODO @Marcel: Remove and move this to atoms!!!
    fidgetState: any,
    setFidgetState: any,

}

export default (props: SimCanvasProps) => {


    // State
    const [canvasDims, setCanvasDims] = useState([0, 0]);
    const [simulator, setSimulator] = useState<Fidget|null>(null);

    // Refs 
    const canvasRef = useRef(null);
    const simulatorRef = useRef(null);

    const location = useLocation()

    useEffect(() => {
        props.setFidgetState(null)
    }, [location])

    // Mount 
    useEffect(() => {

        // Add window resize listn
        window.addEventListener('resize', resizeCanvas);

        resizeCanvas();

        // Unmount
        return (() => {
            // TODO: Clear window resize listner
        })


    }, []);

    // Canvas ref change? 
    React.useEffect(() => {

        // Attach simulator!
        if (!canvasRef.current)
            return;

        const simulator = props.attachNewSimulator(canvasRef.current);
        setSimulator(simulator);

        simulator.sharedState.reactInit(props.setFidgetState)

    }, [canvasRef]);

    // Propagate state shifts to shared state
    // TODO @Marcel: Remove; we use atoms now
    React.useEffect(() => {

        const info = props.fidgetState;

        simulator?.sharedState.write(props.fidgetState?.data || null, SharedStateEntity.REACT)
        
    }, [props.fidgetState])



    const resizeCanvas = () => {

        // Get simulator dims
        if (!simulatorRef || !simulatorRef.current)
            return;

        const rect = document.getElementById('simCanvasWrapper')?.getBoundingClientRect();
        setCanvasDims([rect?.width ?? 0, rect?.height ?? 0]);

    }


    return <div
        className={'simCanvasWrapper' }
        id = {'simCanvasWrapper'}
        ref={simulatorRef}
    > 


        {/* Sequencer Canvas */}
        <canvas className='simCanvas' id={'simCanvas' + props.key}
                ref = {canvasRef}
                width={canvasDims[0]} 
                height={canvasDims[1]} 
        />

    </div>


}
