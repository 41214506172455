import React from 'react';
import './styles/root.css';
import fidgetConfig, { instruments } from './constants/fidgetConfig';

import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Header from './components/Header';
import FidgetPage from './components/Pages/FidgetPage';
import SimCanvas from './components/Sim/SimCanvas';
import LoadSaveModal, { SavedPattern, SavePatternPending } from './components/LoadSaveModal/LoadSaveModal';
import { Provider, useAtomValue } from 'jotai';
import atomStore from './state/atomStore';
import { InstrumentPage } from './components/Pages/InstrumentPage';
import { audioCoreAtom } from './Engines/AudioEngine/core/AudioCore';



const App = () => {

    // Globalized (config) state for the current running Fidget
    const [fidgetState, setFidgetState] = React.useState<SavePatternPending|null>(null)

    const [loadModalOpen, setLoadModalOpen] = React.useState(false)
    
    const audioCore = useAtomValue(audioCoreAtom)


    return <div className='root' onClick={() => audioCore.initAudioIfNeeded()}> 

        <Provider store={atomStore}>
            <Router>

                {/* Routes */}
                <Routes>

                    <Route path = "*" element = {<><Header loadingModalOpen={loadModalOpen} setLoadModalOpen={setLoadModalOpen}
                        />
                    {loadModalOpen && <LoadSaveModal currentFidgetState={fidgetState} setCurrentPattern={setFidgetState} closeModal={() => setLoadModalOpen(false)}/>}
                    </>
                    } 
                    />

                </Routes>
                <Routes>

                    {fidgetConfig.map((fidgetInfo, idx) => {
                        return <Route 
                            key = {idx}
                            path = {fidgetInfo.linkInfo.link}
                            element = {<FidgetPage>
            
                                <SimCanvas 
                                    attachNewSimulator = {fidgetInfo.attachNewSimulator}
                                    key = {idx}
                                    fidgetState = {fidgetState}
                                    setFidgetState = {setFidgetState}
                                />
            
                            </FidgetPage>}
                            
                    />

                    })}

                    {instruments.map((instrument, idx) => {
                        return <Route 
                        key = {idx}
                        path = {instrument.linkInfo.link}
                        element = {<InstrumentPage>
        
                            {instrument.render}
        
                        </InstrumentPage>}
                        />
                    })}


                </Routes>




            </Router>
        </Provider>


    </div>


}

export default App;
