import { R1 } from "../Math/R1";
import { R2New } from "../Math/R2";
import {v4 as uuidv4} from 'uuid'
import Random from "../Math/Random";

export enum Obj2DType {
    CIRCLE,
    POLYGON,
    NONE,
}

export interface CollisionResult {
    depth: number, 
    axis: R2New.Vector,
}


export abstract class Object2D {

    // unique id 
    id: string;
    color: string;

    constructor() {
        this.id = uuidv4()
        this.color = Random.randomColor()
    }

    static load(data: any) {

    }
    abstract save(): void;

    // --- Functions helpful for boundary detection, etc ---
    abstract getProjectedBoundsAlongAxis(axis: R2New.Vector): R1.Range;
    abstract isPointWithin(p: R2New.Vector): boolean;
    abstract computeCenter(): R2New.Vector;

    // --- Motion helpers ---
    abstract move(delta: R2New.Vector): void;

    // --- Rendering ---
    draw(ctx: CanvasRenderingContext2D, renderConfig?: any): void {
        // override me!
    }




}