
import React, { useEffect, useState } from "react"
import { ShatterSynth } from "./synth"
import { useAtomValue } from "jotai"
import { midiSettingAtom } from "../../state/midi_keyboard_state"
import MidiKeyboard, { Key } from "../../components/Keyboards/MidiKeyboard"
import Fidgets from "../../Fidgets";
import { FidgetId } from "../../constants/fidgetConsts"
import SimCanvas from "../../components/Sim/SimCanvas"
import ShatterSim from './sim'
import { SimInstrument } from "../../components/Sim/SimInstrument"

export function Shatter() {

    // Shatter synth, new instance on mount
    const [synth, setSynth] = useState<ShatterSynth>(new ShatterSynth())

    return <>

        <SimInstrument 
            createNewSimulator = {(canvas: HTMLCanvasElement) => {
                        return new ShatterSim(canvas, FidgetId.FISSION);
                    }}
            synth={synth}
        >
        </SimInstrument>


    </>
}