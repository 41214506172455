import Fidget, { FidgetConfig } from "./Fidget";
import * as THREE from 'three';
import { FidgetId } from "../../../constants/fidgetConsts";


export default class Fidget3D extends Fidget {


    scene: THREE.Scene
    camera: THREE.PerspectiveCamera
    renderer: THREE.WebGLRenderer

    constructor(canvas: HTMLCanvasElement, fidgetId: FidgetId, config: FidgetConfig = {}) {

        // Normal fidget constructor
        super(canvas, fidgetId, {skipCtx: true})

        canvas.getContext('webgl');

        // Three.js init

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

        this.renderer = new THREE.WebGLRenderer({canvas: canvas ?? undefined});
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        // document.body.appendChild( renderer.domElement );

    }


}