
import { useState } from 'react'
import { AyisenELTester } from '../../Engines/AudioEngine/ela/ela'
import './midi_keyboard.css'
import { randomColorNoAlpha } from '../../libs/Math/Random'
import { useAtom, useAtomValue } from 'jotai'
import { midiSettingAtom } from '../../state/midi_keyboard_state'
import Select from 'react-select';
import { audioCoreAtom } from '../../Engines/AudioEngine/core/AudioCore'
import { activeSampleAtom, availableSamplesAtom, samplePathAtom } from '../../state/sampleState'
import { LoadingState, loadingAtom } from '../../state/loading'
import { SamplePathSegment } from '../../Engines/AudioEngine/ela/SampleReader'
import { AyisenSynth } from '../../Engines/AudioEngine/AyisenSynth/AyisenSynth'
import { Note, PlayableNote, PlayableNoteList, getNoteIntervalUp } from '../../types/audio/note'


const noop = () => {}

export interface Key {
    color: 'white'|'black'
    note: PlayableNote,
    onClick: () => void
}


const generateKeyboardConfig = (numOctavesDown: number, numOctavesUp: number, root: PlayableNote = "C4") => {

    // Walk up or down the octave. 

    // Here's a map of what to do when you encounter certain semitones. Only 1 octave down / up needed
    // We can base it on the positive octave. we'll %12, and if the num is <0.... then we can add 12 to it to find it's mod number.
    const semiTonesToKeyColor: {[key: number]: 'white'|'black'} = {
        0: 'white',
        1: 'black',
        2: 'white',
        3: 'black',
        4: 'white',

        5: 'white',
        6: 'black',
        7: 'white',
        8: 'black',
        9: 'white',
        10: 'black',
        11: 'white',
    }

    const config: Key[] = []

    // Root from which we'll expand the keyboard out
    const rootNoteIdx = PlayableNoteList.findIndex((e:PlayableNote) => e=== root)

    if (rootNoteIdx === -1) return config

    for (let octave=-numOctavesDown; octave < numOctavesUp; octave++) {

        const semitoneOffset = octave * 12

        for (let semitone = 0; semitone < 12; semitone ++) {

            const note: PlayableNote = PlayableNoteList[rootNoteIdx + semitoneOffset + semitone]


            // Semitone for this level
            config.push({
                color: semiTonesToKeyColor[semitone],
                note,
                onClick: noop,
            })

        }


    }

    return config


}

const keyConfig: Key[] = generateKeyboardConfig(1, 1)


export default ({onKeyClick, onKeyUp, synth}: {
    onKeyClick?: (keys: Key[]) => void, 
    onKeyUp?: (keys: Key[]) => void,
    // Preferrably: just provide it with a synth to control :-)
    synth?: AyisenSynth, // not stateful; only meant to trigger callbacks to audio
}) => {

    const [keyColor, _] = useState<string>(randomColorNoAlpha())

    const [midiConfig, setMidiConfig] = useAtom(midiSettingAtom)
    const [samplePath, setSamplePath] = useAtom(samplePathAtom)

    const [renderControls, setRenderControls] = useState(false)
    
    
    const getRelevantKeys = (key: Key): Key[] => {
        if (midiConfig.mode === 'note') {
            return [key]
        }
        else if (midiConfig.mode === 'major-chord') {
            const list = [key]
            const thirdUp = getNoteIntervalUp(key.note, 4)
            if (thirdUp) list.push({...key, note: thirdUp})
            const fifthUp = getNoteIntervalUp(key.note, 7)
            if (fifthUp) list.push({...key, note: fifthUp})
            return list
        }
        return []
    }

    const keysToNotes = (keys: Key[]): Note[] => {
        return keys.map(key => {
            return {name: key.note, note: key.note}
        })
    }

    const handleKeyClick = (key: Key) => {

        const keys = getRelevantKeys(key)

        // Callback
        if (onKeyClick)
            onKeyClick(keys)

        if (synth) {
            synth.triggerNotes(keysToNotes(keys))
        }

    }

    const handleKeyUp = (key: Key) => {
        
        const keys = getRelevantKeys(key)

        // Callback
        if (onKeyUp)
            onKeyUp(keys)

        // Synth 
        if (synth) {
            synth.releaseNotse(keysToNotes(keys))
        }
    }

    // TODO (P0.5): Get the sample data to live in atom itself for rerender
    const audioCore = useAtomValue(audioCoreAtom)

    const availableSamples = useAtomValue(availableSamplesAtom)
    const activeSample = useAtomValue(activeSampleAtom)


    const allAvailableSampleNames = Object.values(availableSamples).flatMap((kitConfig) => {
        return Object.keys(kitConfig)
    })
    const sampleOpts = allAvailableSampleNames.map(e => {
        return {label: e, value: e}
    })

    const onSelectOption = (option: any) => {
        audioCore.sampleHandler?.loadSpecificSampleName(option.value)
    }


    return <div className='midiCenterer'>

        <div className='midiKeyboard'>

            {keyConfig.map((keyEntry, idx) => {
                if (keyEntry.color === 'white')
                    return <WhiteKey onClick={() => handleKeyClick(keyEntry)} onMouseUp={() => handleKeyUp(keyEntry)} />
                else 
                    return <BlackKey keyColor={keyColor} onClick={() => handleKeyClick(keyEntry)} onMouseUp={() => handleKeyUp(keyEntry)}/>
            })}

        </div>

        <img
            onClick={() => {
                setRenderControls(!renderControls)
            }}
            src={'/resources/icons/settings.png'}
            className='settingsIcon'
        />

        {renderControls && <div className='controlPanel'>

            <label> 
                <input type="checkbox" checked={midiConfig.mode === 'major-chord'} onChange={(e) => {
                    setMidiConfig({...midiConfig, mode: midiConfig.mode === 'note' ? 'major-chord' : 'note'})
                }}/>
                Chord Mode
            </label>

            <Select
                options = {sampleOpts}
                onChange={onSelectOption}
                value={{value: activeSample, label: activeSample}}
                styles={{
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? 'grey' : 'black',
                    }),
                    
                }}
            />

            <h3>Sample Path</h3>
            {samplePath.segments.map((segment, idx) => {

                const updateSamplePath = (update: SamplePathSegment) => {

                    const newSamplePath = {...samplePath}
                    newSamplePath.segments = [...samplePath.segments]
                    newSamplePath.segments[idx] = update
                    
                    setSamplePath(newSamplePath)

                }

                const onRemove = () => {
                    const newSamplePath = {...samplePath}
                    newSamplePath.segments = []
                    for (let i=0; i<samplePath.segments.length; i++) {
                        if (i !== idx)
                            newSamplePath.segments.push(samplePath.segments[i])
                    }
                    setSamplePath(newSamplePath)
                    
                }

                return <SamplePath
                    key={idx}
                    samplePath={segment}
                    onUpdate={updateSamplePath}
                    onRemove={onRemove}
                />
            })}
            <button className='addPathSegmentButton' onClick={() => {
                const newSamplePath = {...samplePath}
                newSamplePath.segments.push({
                    startLoc: 0, 
                    endLoc: 1,
                })
                setSamplePath(newSamplePath)
            }}>Add</button>

            

            {/* <button /> */}

        </div>}

    </div>

}

function SamplePath(props: {samplePath: SamplePathSegment, onUpdate: (update: SamplePathSegment) => void, onRemove: () => void}) {

    const onUpdateStartLoc = (value: string) => {
        const num: number = Number(value)
        if (isNaN(num)) return 
        
        props.onUpdate({...props.samplePath, startLoc: num})

    }

    const onUpdateEndLoc = (value: string) => {
        const num: number = Number(value)
        if (isNaN(num)) return 
        
        props.onUpdate({...props.samplePath, endLoc: num})

    }

    const onUpdateManualPosition = (value: string) => {
        let num: number|undefined = Number(value)
        if (isNaN(num)) num = undefined 
        
        props.onUpdate({...props.samplePath, placementPercent: num})

    }


    return <div className='samplePathRow'>
        <p>Start Loc</p> <input type='number' value={props.samplePath.startLoc} onChange={(e) => onUpdateStartLoc(e.target.value)}/>
        <p>End Loc</p> <input type='number' value={props.samplePath.endLoc} onChange={(e) => onUpdateEndLoc(e.target.value)}/>
        <p>Manual position</p> <input type='number' value={props.samplePath.placementPercent} onChange={(e) => onUpdateManualPosition(e.target.value)}/>
        <button onClick={props.onRemove}>remove</button>
    </div>

}


/**
 * TODO: For some reason, pointerUp is firing super super late. 
 * -> hunch; it's only when audio playback is active. audio thread is blocking some callbacks for sec
 * OnMouseUp is getting triggered all the time all over the place.... might need to detatch this for now. 
 */
const WhiteKey = ({onClick, onMouseUp}: {onClick: () => void, onMouseUp: () => void}) => {
    // TODO: handle mouse lease if mosue is down at the time of leave
    return <div className='whiteKey' onPointerDown={onClick} onPointerUp={onMouseUp}>

    </div>
}

const BlackKey = ({onClick, onMouseUp, keyColor}: {onClick: () => void, keyColor: string, onMouseUp: () => void}) => {
    // TODO: handle mouse lease if mosue is down at the time of leave

    return <div className='blackKey' style={{backgroundColor: keyColor}} onPointerDown={onClick} onPointerUp={onMouseUp}>

    </div>
}