
import AyisenMath from "../../../libs/Math";
import { Geometry, generateNGon } from "../../../libs/Math/Geometry";
import { R2New } from "../../../libs/Math/R2";
import Random from "../../../libs/Math/Random";

enum PathShape {
    CIRCLE,
    TRIANGLE_DOWN,
    SQUARE,
    OCTAGON,
    RANDOM,
}

const thetaToCoord = async (center: {x: number, y: number}, radius: number, theta: number) => {
    return {x: Math.floor(center.x + radius *Math.cos(theta)), y: Math.floor(center.y + radius *Math.sin(theta))};
}


const isOverCircle = (circleCenter: R2New.Vector, circleW: number, coord: R2New.Vector) => {

    const centerDist = AyisenMath.R2New.distance(circleCenter, coord);

    if (centerDist < circleW)
        return true;

    return false;

}


const pathOutShape = (ctx: CanvasRenderingContext2D, x: number, y: number, r: number, shape: PathShape) => {

    switch(shape) {

        case PathShape.CIRCLE: {
            ctx.beginPath();
            ctx.arc(x, y, r, 0, 2 * Math.PI);        
            break;
        }

        case PathShape.TRIANGLE_DOWN: {
            ctx.beginPath();
            ctx.moveTo(x-r/2, y-r/2)
            ctx.lineTo(x+r/2, y-r/2)
            ctx.lineTo(x, y+r/2)
            ctx.lineTo(x-r/2, y-r/2)
            break;
        }

        case PathShape.SQUARE: {
            ctx.beginPath();
            ctx.moveTo(x-r/2, y-r/2)
            ctx.lineTo(x+r/2, y-r/2)
            ctx.lineTo(x+r/2, y+r/2)
            ctx.lineTo(x-r/2, y+r/2)
            ctx.lineTo(x-r/2, y-r/2)
            break;
        }

        case PathShape.OCTAGON: {
            ctx.beginPath();
            const pts = generateNGon(8, {x, y}, r)
            ctx.moveTo(pts[0].x, pts[0].y)
            // We start by moving to 2nd item, and wrap all the way to first
            for (let i=1; i<=pts.length; i++) {
                const nextPt = pts[i%pts.length]
                ctx.lineTo(nextPt.x, nextPt.y)
            }
            break
        }


        case PathShape.RANDOM: {
            const randomShape: PathShape = Random.randomChoice([PathShape.CIRCLE, PathShape.SQUARE, PathShape.TRIANGLE_DOWN, PathShape.OCTAGON])
            pathOutShape(ctx, x, y, r, randomShape)
        }

    }

}


export default {

    isOverCircle,


    drawRect: async (ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, color = 'red', opacity = 1) => {

        // Save context
        ctx.save();

        // Set color 
        ctx.fillStyle = color;
        ctx.globalAlpha = opacity;

        // Draw arc
        ctx.beginPath();
        ctx.rect(x, y, w, h);

        // Stroke
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.fill();

        ctx.globalAlpha = 1;


        // Restore context
        ctx.restore();

    },

    drawCircle: async (ctx: CanvasRenderingContext2D, x: number, y: number, r: number, color = 'red', opacity = 1) => {

        // Save context
        ctx.save();

        // Set color 
        ctx.fillStyle = color;
        ctx.globalAlpha = opacity;

        // Draw arc
        ctx.beginPath();
        ctx.arc(x, y, r, 0, 2 * Math.PI);

        // Fill ? 
        // ctx.fillStyle = 'green';
        // ctx.fill();

        // Stroke
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.fill();

        ctx.globalAlpha = 1;


        // Restore context
        ctx.restore();

    },

    drawRipple: async (ctx: CanvasRenderingContext2D, x: number, y: number, r: number, w = 5, color = 'blue', opacity = 1) => {


        // Save context
        ctx.save();

        // Set color 
        ctx.fillStyle = color;
        ctx.globalAlpha = opacity;

        // Draw arc
        pathOutShape(ctx, x, y, r, PathShape.CIRCLE)

        // Fill ? 
        // ctx.fillStyle = 'green';
        // ctx.fill();

        // Stroke
        ctx.lineWidth = w;
        ctx.strokeStyle = color;
        ctx.stroke();

        ctx.globalAlpha = 1;


        // Restore context
        ctx.restore();


    },

    // dandelion: async (ctx: CanvasRenderingContext2D, geometry, center, radius) => {

    //     // Save context
    //     ctx.save();
        

    //     if (geometry.points.length == 0) {
    //         ctx.restore();
    //         return;
    //     }

    //     ctx.translate(center.x, center.y);
    //     ctx.rotate(geometry.rotation);
    //     ctx.translate(-center.x, -center.y);



    //     // TODO @Marcel: Set color
    //     ctx.lineWidth = geometry.w;
    //     ctx.strokeStyle = geometry.color;
    //     ctx.globalAlpha = geometry.opacity;

    //     ctx.beginPath();
    //     const firstCoord = await thetaToCoord(center, radius, geometry.points[0].theta)
    //     ctx.moveTo( firstCoord.x, firstCoord.y);

    //     // TODO @Marcel: Could be optimized
    //     for (let i=1; i<geometry.points.length; i++) {
    //         const point = await thetaToCoord(center, radius, geometry.points[i].theta);
    //         ctx.lineTo(point.x, point.y);
    //     }

    //     ctx.closePath();

        

    //     ctx.stroke();

    //     // Restore context
    //     ctx.restore();


    // },

    drawGeometry: async (ctx: CanvasRenderingContext2D, geometry: {points: {theta: number}[], rotation: number, color: string, w: number}, center: {x: number, y: number}, radius: number) => {

        // Save context
        ctx.save();
        

        // const points = geometry.points.map( point => {
        //     return {x: Math.floor(center.x + radius *Math.cos(point.theta)), y: Math.floor(center.y + radius *Math.sin(point.theta))};
        // });

        if (geometry.points.length == 0) {
            ctx.restore();
            return;
        }

        ctx.translate(center.x, center.y);
        ctx.rotate(geometry.rotation);
        ctx.translate(-center.x, -center.y);



        // TODO @Marcel: Set color
        ctx.lineWidth = geometry.w;
        ctx.strokeStyle = geometry.color;

        ctx.beginPath();
        const firstPoint = {x: Math.floor(center.x + radius *Math.cos(geometry.points[0].theta)), y: Math.floor(center.y + radius *Math.sin(geometry.points[0].theta))}
        ctx.moveTo(firstPoint.x, firstPoint.y);

        // TODO @Marcel: Could be optimized
        for (let i=1; i<geometry.points.length; i++) {
            const point = {x: Math.floor(center.x + radius *Math.cos(geometry.points[i].theta)), y: Math.floor(center.y + radius *Math.sin(geometry.points[i].theta))}
            ctx.lineTo(point.x, point.y);
        }

        ctx.closePath();

        

        ctx.stroke();

        // Restore context
        ctx.restore();


    },

    drawGeometry2: async (ctx: CanvasRenderingContext2D, geometry: Geometry, w: number, color: string, fill: boolean) => {

        // Save context
        ctx.save();
        

        // const points = geometry.points.map( point => {
        //     return {x: Math.floor(center.x + radius *Math.cos(point.theta)), y: Math.floor(center.y + radius *Math.sin(point.theta))};
        // });

        if (geometry.length == 0) {
            ctx.restore();
            return;
        }

        // ctx.translate(center.x, center.y);
        // ctx.rotate(geometry.rotation);
        // ctx.translate(-center.x, -center.y);



        // TODO @Marcel: Set color
        ctx.lineWidth = w;
        ctx.strokeStyle = color;

        ctx.beginPath();

        const firstPoint = geometry[0]
        ctx.moveTo(firstPoint.x, firstPoint.y);

        // TODO @Marcel: Could be optimized
        for (let i=1; i<geometry.length; i++) {
            ctx.lineTo(geometry[i].x, geometry[i].y);
        }

        ctx.closePath();

        

        ctx.stroke();
        if (fill) {
            ctx.fillStyle = color;
            ctx.fill();
        }

        // Restore context
        ctx.restore();


    },

    /**
     * https://stackoverflow.com/questions/7054272/how-to-draw-smooth-curve-through-n-points-using-javascript-html5-canvas/7058606#7058606
     */
    drawSmoothGeoApprox: async (ctx: CanvasRenderingContext2D, geometry: Geometry, w: number, color: string, fill: boolean, stroke: boolean) => {

        // Save context
        ctx.save();
        

        // const points = geometry.points.map( point => {
        //     return {x: Math.floor(center.x + radius *Math.cos(point.theta)), y: Math.floor(center.y + radius *Math.sin(point.theta))};
        // });

        if (geometry.length == 0) {
            ctx.restore();
            return;
        }

        // ctx.translate(center.x, center.y);
        // ctx.rotate(geometry.rotation);
        // ctx.translate(-center.x, -center.y);



        // TODO @Marcel: Set color
        ctx.lineWidth = w;
        ctx.strokeStyle = color;

        ctx.beginPath();

        const firstPoint = geometry[0]
        ctx.moveTo(firstPoint.x, firstPoint.y);

        // TODO @Marcel: Could be optimized
        for (let i=1; i<geometry.length-1; i++) {

            const curPoint = geometry[i % geometry.length]
            const lastPoint = geometry[i-1]
            const nextPoint = geometry[(i+1) % geometry.length]

            // Line to a LITTLE before this point
            
            // ... then curve to this point

            // const trajectoryIn = R2New.sub(geometry[i], lastPoint)
            // const trajectoryOutBackwards = R2New.sub(geometry[i], nextPoint)

            // // -> This leads to fun lol (w/ no added control point scaling factor)
            // // const cornerPointDir = R2New.add(trajectoryIn, trajectoryOutBackwards)

            // const cornerPointDir = R2New.norm(R2New.add(trajectoryIn, trajectoryOutBackwards)).norm

            // let contorlPoint = R2New.add(geometry[i], R2New.mul(cornerPointDir, 1))
            // ctx.quadraticCurveTo(contorlPoint.x, contorlPoint.y, geometry[i].x, geometry[i].y);

            
            // Control point will be a bit inward from that :-)

            // const dispToThisPoint = R2New.sub(geometry[i], lastPoint)
            // const mostWayThere = R2New.add(lastPoint, R2New.mul(dispToThisPoint, 0.8))
            // ctx.lineTo(mostWayThere.x, mostWayThere.y)

            // Control point will be the intersection of these two lines 
            // ... wait, no lol. that will just be this point LOL. 
            // I want to pull the curving out a little...
            // const lineToLast = R2New.sub()

             // Try 2: 
            // - Control point: Midpiont of this & last point
            // - Point: This point
            const controlPoint = R2New.mul(R2New.add(curPoint, nextPoint), 0.5)
            ctx.quadraticCurveTo(curPoint.x, curPoint.y, controlPoint.x, controlPoint.y)


        }

        // Curve through the last 2 points
        ctx.quadraticCurveTo(geometry[geometry.length-1].x, geometry[geometry.length-1].y, geometry[0].x, geometry[0].y)

        // ctx.closePath();

        

        if (stroke)
            ctx.stroke();
        if (fill) {
            ctx.fillStyle = color;
            ctx.fill();
        }

        // Restore context
        ctx.restore();


    },


    drawLine: async (ctx: CanvasRenderingContext2D, p1: R2New.Vector, p2: R2New.Vector, width: number, color = 'rgb(100, 100, 100)') => {

        // Save context
        ctx.save();

        // Set stroke params
        ctx.lineWidth = width;
        ctx.strokeStyle = color;

        // Vertical line
        ctx.beginPath();
        ctx.moveTo(p1.x, p1.y);
        ctx.lineTo(p2.x, p2.y);
        ctx.stroke();

        // Restore context
        ctx.restore();

    },


    drawCrosshair: async (ctx: CanvasRenderingContext2D, center: R2New.Vector, width: number, lineWidth = 2, color = 'rgb(100, 100, 100)') => {

        // Save context
        ctx.save();

        // Set stroke params
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;

        // Define points
        const T = {x: center.x, y: center.y - width/2};
        const B = {x: center.x, y: center.y + width/2};
        const L = {x: center.x - width/2, y: center.y};
        const R = {x: center.x + width/2, y: center.y};

        // Vertical line
        ctx.beginPath();
        ctx.moveTo(T.x, T.y);
        ctx.lineTo(B.x, B.y);
        ctx.stroke();

        // Horizontal line
        ctx.beginPath();
        ctx.moveTo(L.x, L.y);
        ctx.lineTo(R.x, R.y);
        ctx.stroke();

        // Restore context
        ctx.restore();


    },


    // drawGeometry1: async (ctx: CanvasRenderingContext2D, geometry, center, radius) => {

    //     // Save context
    //     ctx.save();
        

    //     const points = geometry.points.map( point => {
    //         return {x: Math.floor(center.x + radius *Math.cos(point.theta)), y: Math.floor(center.y + radius *Math.sin(point.theta))};
    //     });

    //     if (points.length == 0) {
    //         ctx.restore();
    //         return;
    //     }

    //     ctx.translate(center.x, center.y);
    //     ctx.rotate(geometry.rotation);
    //     ctx.translate(-center.x, -center.y);



    //     // TODO @Marcel: Set color
    //     ctx.lineWidth = geometry.w;
    //     ctx.strokeStyle = geometry.color;

    //     ctx.beginPath();
    //     ctx.moveTo(points[0].x, points[0].y);

    //     // TODO @Marcel: Could be optimized
    //     for (let i=1; i<points.length; i++) {
    //         ctx.lineTo(points[i].x, points[i].y);
    //     }

    //     ctx.closePath();

        

    //     ctx.stroke();

    //     // Restore context
    //     ctx.restore();


    // },



    drawBubble: async (ctx: CanvasRenderingContext2D, center: R2New.Vector, radius: number, color: string, opacity = 1) => {

        // Save context
        ctx.save();

        // Set color 
        ctx.fillStyle = color;
        ctx.globalAlpha = opacity;

        // Draw arc
        ctx.beginPath();
        ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);

        // Stroke
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.fill();

        ctx.globalAlpha = 1;


        // Restore context
        ctx.restore();

    },


}