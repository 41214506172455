import { generateNGon } from "../Math/Geometry";
import { R1 } from "../Math/R1";
import { R2New } from "../Math/R2";
import Random from "../Math/Random";
import { Circle } from "./Circle";
import { CollisionResult, Obj2DType, Object2D } from "./Object";


export enum PolygonRenderStyle {
    OUTLINE,
    FILL
}

export type PolygonRenderConfig = {
    style: PolygonRenderStyle.OUTLINE,
    outlineWidth: number,
    colorOverride?: string,
    opacity?: number,
} | {
    style: PolygonRenderStyle.FILL,
    colorOverride?: string,
    opacity?: number,
}

/**
 * A simple polygon object! 
 * 
 * TODO @mlegros: think through what this will mean for state / atom storage that's needed for audio processing....
 * Audio stuff will probably care mostly about EVENTS: 
 * - 2 things collided
 * ... or PREDICTIONS
 * - Overlap over time. 
 * 
 * If something needs to be realtime... it should be predicted and loaded up on the audio-compute already. 
 * If something does not.... then I should be able to trigger it right from the fidget when i need to.... 
 * 
 * So that's that lolol. I think this should work fine. 
 * 
 */
export class Polygon extends Object2D {

    points: R2New.Vector[] = []
    color: string

    // Points must be in connection-order
    constructor(points: R2New.Vector[], color=Random.randomColor()) {
        super()
        this.points = [...points]
        this.color = color

    }

    static load(input: any): Polygon|null {
        if (input.type !== "polygon") return null
        if (!input.points || input.points.length <= 0) return null

        return new Polygon(input.points, input.color)
    }

    save() {
        return {
            type: "polygon", // todo: enum to ensure unique
            points: this.points,
            color: this.color,
        }
    }

    /**
     * Generate an NGon from scratch
     */
    static generateNGon(n: number, center: R2New.Vector, radius: number) {
        return new Polygon(generateNGon(n, center, radius))
    }

    projectPointsAlongAxis(axis: R2New.Vector) {
        // Ensure the axis is normalized
        const normalizedAxis = R2New.norm(axis).norm

        // Dot product with each point
        return this.points.map(point => {
            return R2New.dot(point, normalizedAxis)
        })
    }

    doBoundingBoxesOverlap(other: Polygon) {
        // todo
    }

    computeCenter() {
        // avg the x & y values
        let aggregate: R2New.Vector = {x: 0, y: 0}
        for (let point of this.points) aggregate = R2New.add(aggregate, point)
        return R2New.mul(aggregate, 1/this.points.length)
    }

    getProjectedBoundsAlongAxis(axis: R2New.Vector): R1.Range {
        const selfProjections = this.projectPointsAlongAxis(axis).sort((a,b) => a-b) // (a,b) => a<b ? -1 : b<a? 1 : 0
        return {min: selfProjections[0], max: selfProjections[selfProjections.length-1]}
    }

    /**
     * Detect a collision between 2 polygons
     */
    detectCollision(other: Polygon): CollisionResult|null {

        // TODO @mlegros: Integrate separating-axis-theorem https://youtu.be/Zgf1DYrmSnk?feature=shared
    
        // OPTIMIZATION: We can probably do a simple bounding box overalp test first

        // First, compute normals for both polygons, to check. 
        const selfNormals = this.computeNormals();
        const otherNormals = other.computeNormals();

        const collisions: CollisionResult[] = []

        // Now, we need to iterate over the normals and find collisions. 
        for (let normal of [...selfNormals, ...otherNormals]) {

            // Both along axis 
            const selfProjections = this.projectPointsAlongAxis(normal).sort((a,b) => a-b) // (a,b) => a<b ? -1 : b<a? 1 : 0
            const otherProjections = other.projectPointsAlongAxis(normal).sort((a,b) => a-b)
            // console.warn("Self Proj: ", selfProjections, "Other Proj: ", otherProjections)

            // Compute amount of overlap
            const overlap = R1.computeRangeOverlap(
                {min: selfProjections[0], max: selfProjections[selfProjections.length-1]},
                {min: otherProjections[0], max: otherProjections[otherProjections.length-1]},
            )

            // None -> we found a gap!
            if (overlap < 0) {
                return null
            }
            // Some -> catalog depth for later resolution help
            else {

                // Center -> center offset
                collisions.push({
                    depth: overlap,
                    axis: normal
                })
            }

        }
        
        // Pick the largets depth to return 
        let smallest: CollisionResult = collisions[0]
        for (let collision of collisions) {
            if (collision.depth < smallest.depth) smallest = collision
        } 
        return smallest

    }

    detectCircleCollision(circle: Circle) {
        // TODO: Needs testing

        // Compute the normals for this object
        const normals = this.computeNormals()

        const collisions: CollisionResult[] = []

        // Poject along each normal & check if the circles boundaries encroach 
        for (let normal of normals) {

            // Project all the points on this along 
            const selfProjections = this.projectPointsAlongAxis(normal).sort((a,b) => a-b)
            const circleCenterProjections = R2New.dot(normal, circle.center)

            // The circle's min and max will just be the projected center + & - circle.radius
            // Compute amount of overlap
            const overlap = R1.computeRangeOverlap(
                {min: selfProjections[0], max: selfProjections[selfProjections.length-1]},
                {min: circleCenterProjections-circle.radius, max: circleCenterProjections+circle.radius},
            )

            // TODO: lots of this overlaps with the polygon function. compress
            // None -> we found a gap!
            if (overlap < 0) {
                return null
            }
            // Some -> catalog depth for later resolution help
            else {
                // Center -> center offset
                collisions.push({
                    depth: overlap,
                    axis: normal
                })
            }

        }

        // Pick the largets depth to return 
        let smallest: CollisionResult = collisions[0]
        for (let collision of collisions) {
            if (collision.depth < smallest.depth) smallest = collision
        } 
        return smallest

    }

    computeLineSegments() {
        let segments: R2New.Vector[] = []
        for (let i=0; i<this.points.length; i++) {

            const p1 = this.points[i]
            const p2 = this.points[(i+1) % this.points.length]

            segments.push(R2New.sub(p2, p1))

        }
        return segments
    }


    computeNormals() {
        // Get semgments
        const segments = this.computeLineSegments()

        // Normalize & rotate 90deg
        return segments.map(segment => {
            return R2New.orthogonal(R2New.norm(segment).norm)
        })
    }

    isPointWithinBoundingBox(point: R2New.Vector) {
        const xVals = this.points.map(e => e.x)
        const xMin = Math.min(...xVals)
        if (point.x < xMin) return false
        const xMax = Math.max(...xVals)
        if (point.x > xMax) return false

        const yVals = this.points.map(e => e.y)
        const yMin = Math.min(...yVals)
        if (point.y < yMin) return false
        const yMax = Math.max(...yVals)
        if (point.y > yMax) return false

        return true
    }

    isPointWithin(point: R2New.Vector) {

        // Before expensive compute: check if the min / max of the polygon make sense for the point
        if (!this.isPointWithinBoundingBox(point)) return false

        // Now, we need to check how many times the sides of this object intersect... (ray casting)
        // TODO: finish 

        return true;

    }


    draw(ctx: CanvasRenderingContext2D, renderConfig: PolygonRenderConfig) {

        // Save context
        ctx.save();

        // Set color 
        ctx.fillStyle = renderConfig.colorOverride ?? this.color;
        ctx.globalAlpha = renderConfig.opacity ?? 1;

        // Draw shape
        ctx.beginPath();
        ctx.moveTo(this.points[0].x, this.points[0].y)
        // We start by moving to 2nd item, and wrap all the way to first
        for (let i=1; i<=this.points.length; i++) {
            const nextPt = this.points[i%this.points.length]
            ctx.lineTo(nextPt.x, nextPt.y)
        }

        // Fill ? 
        if (renderConfig.style === PolygonRenderStyle.FILL) {
            ctx.fillStyle = renderConfig.colorOverride ?? this.color
            ctx.fill()
        }
        // Outline ?
        else if (renderConfig.style === PolygonRenderStyle.OUTLINE) {
            ctx.lineWidth = renderConfig.outlineWidth
            ctx.strokeStyle = renderConfig.colorOverride ?? this.color
            ctx.stroke();
        }

        // Restore context
        ctx.globalAlpha = 1;
        ctx.restore();

    }

    move(delta: R2New.Vector): void {
        this.points = this.points.map(point => R2New.add(point, delta))
    }


}

